import * as API from '@/api/index'

/* 增 */
export const addRepair = (params) => {
  return API.POST('/WxRepair/addRepair', params);
}

/* 根据code查询openId */
export const findOpenIdByCode = (params) => {
  return API.GET('/WxRepair/findOpenIdByCode', params, { notLoading: true });
}

/* 文件上传 */
export const filesUpload = (params) => {
  return API.POSTFILE('/A00CommonController/filesUpload', params);
}
