import { Uploader, Notify, Dialog } from 'vant';
import * as api from './maintenance-api.js'
import orderOperation from '@/mixins/order-operation.js'
import { Popup, Picker } from 'vant';
import Amap from '@/mixins/map/map.js'
export default {
    mixins: [orderOperation, Amap],

    components: {
        Uploader,
        [Notify.Component.name]: Notify.Component,
        [Dialog.Component.name]: Dialog.Component,
        Popup,
        Picker,
    },
    data () {
        return {
            detail: {
                capplicantId: '',
                "capplicantName": "",
                "ccompany": "",
                "cproject": "",
                "cremark": "",
                "ctelNum": "",
                "photos": [],
            },

            rules: {
                ccompany: '公司不能为空',
                cproject: '项目不能为空',
                capplicantName: '报修人不能为空',
                ctelNum: '联系电话不能为空',
            },

            popupVisible: false,
            popupList: [],

            // 对话框
            dialogVisible: true,
        }
    },

    mounted() {
        
        // this.findRepairPersonnel()  // 查询人员列表

        this.findOpenIdByCode()
    },

    methods: {
        
        /* api */
        // 照片上传
        uploadPhoto (params) {

            this.detail.photos.pop()

            let {
                file
            } = params

            let type = file.type
            if (type != 'image/png' && type != 'image/jpeg' && type != 'image/gif') {
                Notify({ type: 'warning', message: '请上传png或jpg格式图片' });
                return
            }

            const formdata = new FormData()
            formdata.append('file',file)

            api.filesUpload (formdata).then(data => {
                if (data) {
                    let d = data.data.resultObject
                    this.detail.photos.push({ url: this.$baseUrl + d })
                }
            })
        },

        // 增
        addRepair () {
            let {
                capplicantName,
                ccompany,
                cproject,
                cremark,
                ctelNum,
                photos,
            } = this.detail

            let {
                lng: longitude,
                lat: latitude,
            } = this

            photos = photos.map(t => t.url)

            // 校验
            let emptyRes = Object.keys(this.rules).some(key => {
                
                if (this.detail[key] == '') {
                    Notify({ type: 'warning', message: this.rules[key] });
                    return 1
                }
            })
            if (emptyRes) return

            api.addRepair ({
                capplicantName,
                capplicantId: localStorage.getItem('man-openId'),
                ccompany,
                cproject,
                cremark,
                ctelNum,
                photos,
                longitude,
                latitude,
            }).then(data => {
                if (data) {
                    Notify({ type: 'success', message: '新增成功' });
                    this.$router.replace('/processing-center')
                }
            })
        },

        // 根据code查询openId
        findOpenIdByCode () {
            let openId = localStorage.getItem('man-openId')
            if (openId && openId != 'null') this.detail.capplicantId = openId

            
            else {
                api.findOpenIdByCode({code: localStorage.getItem('man-code')}).then(data => {
                    if (data) {
                        localStorage.setItem('man-openId', data.data.resultObject)
                    }
                })
            }
        },

        // IP定位获取当前城市信息

        /* handle */
        // 弹出框
        openPopup () {
            this.popupVisible = true
        },
        onConfirm (val) {
            this.detail.capplicantName = val.cname
            this.detail.capplicantId = val.cwpId
            this.popupVisible = false
        },
    },
}